import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import TitleStyle from "../components/TitleStyle/TitleStyle"
import Content from "../components/Content/Content"
import Seo from "../components/SEO/SEO"
import FlexDiv from "../components/FlexDiv/FlexDiv"
import ButtonStyle from "../components/ButtonStyle/ButtonStyle"

const ProductPage = () => {
    const productImg2 = useStaticQuery(graphql`
        query productImg2 {
            allImageSharp(filter: {fluid: {originalName: {eq: "7.png"}}}) {
                edges {
                    node {
                        fluid {
                            sizes
                            src
                            originalName
                        }
                    }
                }
            }
            }
    `)
  return (
    <Layout>
    <Seo title="Merce Krem przeciwbólowy 200g" />
      <Content>
        <TitleStyle>Merce Krem przeciwbólowy 200g</TitleStyle>
        <div>
            <p>Zawiera substancje czynne, ziołowe które: łagodzą stany zapalne, zmniejszają bóle mięśni, stawów i chrząstek, chłodzą powierzchnię skóry i zwiększają przepływ krwi, wzmacniają wchłanianie substancji czynnych i wchłanianie do głębokich warstw skóry.</p>
        </div>
        <FlexDiv justify='space-evenly' align='center'>
            <img height='350px' src={productImg2.allImageSharp.edges[0].node.fluid.src} alt={productImg2.allImageSharp.edges[0].node.fluid.originalName} />
            <div>
                <p>89,99 zł</p>
                <ButtonStyle>KUP</ButtonStyle>
            </div>
        </FlexDiv>
        <div>
            <p>Zastosowanie:</p>
            <p>Substancje aktywne proponowane w przypadku następujących problemów: urazy spowodowane uprawianiem sportu, zwichnięcia, bóle mięśni, bóle mięśni po treningu, naciągnięcie mięśni, chroniczne lub okolicznościowe bóle stawów, reumatyzm, dna moczanowa, choroby zwyrodnieniowe stawów, neuropatie uciskowe, bóle chrząstek.</p>
            <p>Sposób użycia:</p>
            <p>Dokładnie oczyść powierzchnię skóry, która ma być poddana zabiegowi. W przypadku dolegliwości przewlekłych zalecamy dokładne wcieranie kremu kilka razy dziennie przez co najmniej kilka minut.  W celu leczenia i zapobiegania długotrwałym dolegliwościom zaleca się stosowanie kremu przez co najmniej kilka tygodni, jednak w przypadku ostrych dolegliwości wystarczy kilkudniowe stosowanie. Chłodzący efekt kremu pojawia się w ciągu 10 minut od nałożenia kremu i można go odczuć przez około 50-60 minut. Zaleca się, aby między ponownym użyciem kremu upłynęło co najmniej 2-3 godziny. Działanie chłodzące kremu pojawi się w ciągu 10 minut po nałożeniu kremu i odczuwalne jest przez mniej więcej 50-60 minut. W celu ponownego zastosowania kremu przeciwbólowego proponujemy odczekać co najmniej 2-3 godziny. </p>
            <p>Środki ostrożności: Krem do stosowania wyłącznie zewnętrznego. W przypadku przedostania się do oczu, ust lub na otwartą ranę niezwłocznie przepłukać miejsce pod bieżącą wodą. Krem wchłaniany jest w ciągu 20-30 minut. Nie pozostawia plam na ubraniu.</p>
            <p>Składniki: </p>
            <p>woda termalna,	olej jojoba, olej z awokado, mentol, siarczan glukozaminy, arnika górska, lukrecja, kora wierzby białej, nagietek, kurkuma, orzech laskowy, dziurawiec, rumianek (trawa lekarska), wyciąg z kasztanowca, malwa, lipa wielkokwiatowa, mięta pieprzowa, krwawnik pospolity, rozmaryn, mędrzec, juniper, jałowiec, duża pokrzywa, żeń-szeń, siarczan chondroityny, magnez, wodorosty czerwone (wodorosty irlandzkie), koenzym Q10, olejek miętowy, lawenda, aloes, olejek eukaliptusowy, olejek cynamonowy, olejek rozmarynowy, olejek rozmarynowy, kolagen morski, kamfora, beta-karoten, prowitamina B5, alantoina, </p>
            <p>Najlepiej zużyć przed końcem: data ważności i numer serii na opakowaniu</p>
            <p>Kraj pochodzenia: Węgry</p>
            <p>Gyógyvarázs Kereskedelmi Kft.</p>
            
            <p>4400 Nyíregyháza, Víz u. 21/b.</p>
            <p>www.gyogyvarazs.hu</p>
        </div>
      </Content>
    </Layout>
  )
}

export default ProductPage